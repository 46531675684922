<template>
  <div class="">
    <AddPaymentModal />
    <i
      class="fa fa-ellipsis-v text-primary d-flex align-items-center justify-content-center"
      @click="openPaymentMenu"
      v-click-outside="closePaymentMenu"
    ></i>
    <div v-if="paymentMenu" class="payment-options">
      <ul>
        <!-- <li>
          <router-link to="/debit-note" class="nav-link">
            <i class="fa fa-credit-card"></i>
            <span>Debit Note</span>
          </router-link>
        </li> -->
        <li>
          <router-link  :to="/show-payment-receivable/ + payment.id" class="nav-link">
            <i class="fa fa-money-bill"></i>
            <span>Show Payments</span>
          </router-link>
        </li>
        <li @click="callModal(payment)">
          <a class="nav-link">
            <i class="fa fa-plus"></i>
            <span>Add Payment</span>
          </a>
        </li>
        <li>
          <router-link :to="/edit-payment-receivable/ + payment.id" class="nav-link">
            <i class="fa fa-edit"></i>
            <span>Update</span>
          </router-link>
        </li>
        <li>
          <router-link  :to="/payment-receivable-detail/ + payment.id" class="nav-link">
            <i class="fa fa-eye"></i>
            <span>Credit Note</span>
          </router-link>
        </li>
        <li @click="deletePayment(payment.id)">
          <a class="nav-link">
            <i class="fa fa-trash"></i>
            <span>Delete</span>
          </a>
        </li>
      </ul>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import AddPaymentModal from "./AddPaymentModal.vue";
export default {
  name: "Eclips",
  props: {
    payment: Object
  },
  components: { AddPaymentModal },
  data() {
    return {
      paymentMenu: false,
    };
  },
  methods: {
    callModal: function(payment) {
      this.$root.$emit("openAddPaymentReceiveModal",payment);
    },
     openPaymentMenu: function() {
      this.paymentMenu = !this.paymentMenu;
    },

    closePaymentMenu: function() {
      this.paymentMenu = false;
    },
    
    async deletePayment(id) {
      var formData = new FormData();
      formData.append("id", id);
      this.$swal({
        title: "Are you sure you want to <br /> delete Payment ?",
        text: "You can’t undo this action.",
        icon: "error",
        confirmButtonText: "Delete",
        showCancelButton: true
      }).then(result => {
        if (result.isConfirmed) {
          return axios
            .post(
              process.env.VUE_APP_API_URL + "paymentReceivable/delete",
              formData,
              {
                headers: {
                  "Content-Type": "multipart/form-data"
                }
              }
            )
            .then(response => {
              if (response.data.statusCode == 200) {
                this.$root.$emit("removePaymentReceivable", id);
                this.$swal("Deleted!", "Payment has been deleted.", "success");
              } else {
                this.errorMessage = response.data.message;
              }
            });
        }
      });
    }
  }
};
</script>

<style></style>
