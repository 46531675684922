<template>
  <div class="container-fluid">
    <div class="row">
      <div class="col-md-12 p-0">
        <form>
          <div class="form-row">
            <div class="col-lg-4">
              <div class="form-group">
                <label for="reference">
                  Reference
                  <span class="text-danger">*</span>
                </label>
                <input
                  type="text"
                  class="form-control pl-2"
                  name="reference"
                  required
                  autocomplete="off"
                  placeholder="Reference"
                  v-model.trim="$v.details.reference.$model"
                  :class="{
                    'is-invalid': $v.details.reference.$error,
                    'is-valid': !$v.details.reference.$invalid,
                  }"
                />

                <div class="invalid-tooltip">
                  <span v-if="!$v.details.reference.required">Reference is required</span>
                </div>
              </div>
            </div>
             <div class="col-lg-4">
              <div class="from-group">
                <div class="form-group">
                  <label for="customer_id">
                    Customers
                    <span class="text-danger">*</span>
                  </label>
                  <select
                    class="form-control pl-1"
                    name="customer_id"
                    v-model="details.customer_id"
                    id="customer_id"
                    :class="{
                      'is-invalid': $v.details.customer_id.$error,
                      'is-valid': !$v.details.customer_id.$invalid,
                    }"
                  >
                    <option value selected disabled>Select Customers</option>
                    <option
                      v-for="item in customersOptions"
                      :key="item.id"
                      :value="item.id"
                    >{{ item.customer_name }}</option>
                  </select>
                  <div class="invalid-tooltip">
                    <span v-if="!$v.details.customer_id.required">Customers is required</span>
                  </div>
                </div>
              </div>
            </div>

            <div class="col-lg-4">
              <div class="form-group">
                <label for="date">
                  Date
                  <span class="text-danger">*</span>
                </label>
                <input
                  type="date"
                  class="form-control px-2"
                  name="date"
                  required
                  value="2022-11-26"
                  v-model.trim="$v.details.date.$model"
                  :class="{
                    'is-invalid': $v.details.date.$error,
                    'is-valid': !$v.details.date.$invalid,
                  }"
                />
                <div class="invalid-tooltip">
                  <span v-if="!$v.details.date.required">Date is required</span>
                </div>
              </div>
            </div>
          </div>

          <div>
            <table class="table table-sm table-responsive-md" v-if="enableProductStatus">
              <thead class="dark">
                <tr>
                  <th col="span" class="text-center">Product</th>
                  <th col="span" class="text-center">Net Unit Price ({{ this.$currency }})</th>
                  <th col="span" class="text-center">Stock</th>
                  <th col="span" class="text-center">Quantity</th>
                  <th col="span" class="text-center">Discount ({{ this.$currency }})</th>
                  <th col="span" class="text-center">Tax ({{ this.$currency }})</th>
                  <th col="span" class="text-center">Sub Total ({{ this.$currency }})</th>
                  <th col="span" class="text-center">Action</th>
                </tr>
              </thead>
              <tbody>
                <tr v-if="cart.length == 0">
                  <td colspan="8" class="text-center">
                    <span class="text-danger">Please search & select products!</span>
                  </td>
                </tr>
              </tbody>

              <tbody v-if="cart.length > 0">
                <tr v-for="item in cart" :key="item.product.products.id">
                  <td v-if="item.product.products.picture == null">
                    <div class="company-details">
                      <div class="company-logo">
                        <img src="../../assets/images/product-image.png" alt />
                      </div>
                      <h6 class="company-name">
                        {{ item.product.products.product_code }} -
                        {{ item.product.products.product_name }}
                      </h6>
                    </div>
                  </td>
                  <td v-else>
                    <div class="company-details">
                      <div class="company-logo">
                        <img :src="API_URL + item.product.products.picture" alt />
                      </div>
                      <h6 class="company-name">
                        {{ item.product.products.product_code }} -
                        {{ item.product.products.product_name }}
                      </h6>
                    </div>
                  </td>

                  <td col="span" class="text-center">{{ item.product.products.product_price }}</td>
                  <td col="span" class="text-center">
                    {{ item.product.products.product_quantity }}
                    {{ item.product.products.product_unit }}
                  </td>
                  <td col="span" class="quantity-actions">
                    <span class="item-decrement" @click="productdecrement(item.product)">
                      <img src="../../assets/images/minux-grey.png" alt />
                    </span>
                    <span>{{ item.quantity }}</span>
                    <span class="item-increment" @click="productIncrement(item.product)">
                      <img src="../../assets/images/plus.png" alt />
                    </span>
                  </td>
                  <td col="span" class="text-center">{{ item.product_discount_amount }}</td>
                  <td col="span" class="text-center">{{ item.product_tax_amount }}</td>
                  <td col="span" class="text-center">{{ item.sub_total }}</td>
                  <td col="span" class="text-center">
                    <i class="fa fa-trash" @click="deleleItem(item.product)"></i>
                  </td>
                </tr>
              </tbody>
            </table>

            <div class="row justify-content-md-end" v-if="enableProductStatus">
              <div class="col-md-4">
                <div class="table-responsive">
                  <table class="table table-striped">
                    <tr>
                      <th>Order Tax ({{ orderTaxPercentage }}%)</th>
                      <td>(+) {{ this.$currency }}{{ orderTaxAmount }}</td>
                    </tr>
                    <tr>
                      <th>Discount ({{ discountPercentage }}%)</th>
                      <td>(-) {{ this.$currency }}{{ discountAmount }}</td>
                    </tr>
                    <tr>
                      <th>Shipping</th>
                      <td>(+) {{ this.$currency }}{{ shipping }}</td>
                    </tr>
                    <tr>
                      <th>Grand Total</th>
                      <th>(=) {{ this.$currency }}{{ cartTotalPrice }}</th>
                    </tr>
                  </table>
                </div>
              </div>
            </div>

            <div class="form-row">
              <div class="col-lg-4">
                <div class="form-group">
                  <label for="tax_percentage">Order Tax (%)</label>
                  <input
                    type="number"
                    class="form-control pl-2"
                    v-model="details.tax_percentage"
                    min="0"
                    max="100"
                    value="0"
                    @keyup="updateOrderPercentageValue($event.target.value)"
                    required
                  />
                </div>
              </div>
              <div class="col-lg-4">
                <div class="form-group">
                  <label for="discount_percentage">Discount (%)</label>
                  <input
                    type="number"
                    class="form-control pl-2"
                    v-model="details.discount_percentage"
                    min="0"
                    max="100"
                    value="0"
                    @keyup="updateDiscountPercentageValue($event.target.value)"
                    required
                  />
                </div>
              </div>
              <div class="col-lg-4">
                <div class="form-group">
                  <label for="shipping_amount">Shipping ({{ this.$currency }})</label>
                  <input
                    type="number"
                    class="form-control pl-2"
                    v-model="details.shipping_amount"
                    min="0"
                    @keyup="updateShippingValue($event.target.value)"
                    value="0"
                    required
                    step="0.01"
                  />
                </div>
              </div>
            </div>
          </div>

          <div class="form-row">
            <div class="col-lg-4">
              <div class="form-group">
                <label for="status">
                  Status
                  <span class="text-danger">*</span>
                </label>
                <select
                  class="form-control pl-1"
                  name="status"
                  id="status"
                  v-model.trim="$v.details.status.$model"
                  :class="{
                    'is-invalid': $v.details.status.$error,
                    'is-valid': !$v.details.status.$invalid,
                  }"
                >
                  <option value selected>Select Status</option>
                  <option value="Pending">Pending</option>
                  <option value="Ordered">Ordered</option>
                  <option value="Completed">Completed</option>
                </select>
                <div class="invalid-tooltip">
                  <span v-if="!$v.details.status.required">Status is required</span>
                </div>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="form-group">
                <label for="payment_method">
                  Payment Method
                  <span class="text-danger">*</span>
                </label>
                <select
                  class="form-control pl-1"
                  name="payment_method"
                  id="payment_method"
                  v-model.trim="$v.details.payment_method.$model"
                  :class="{
                    'is-invalid': $v.details.payment_method.$error,
                    'is-valid': !$v.details.payment_method.$invalid,
                  }"
                >
                  <option value selected>Select Payment Method</option>
                  <option value="Cash">Cash</option>
                  <option value="Credit Card">Credit Card</option>
                  <option value="Bank Transfer">Bank Transfer</option>
                  <option value="Cheque">Cheque</option>
                  <option value="Other">Other</option>
                </select>
                <div class="invalid-tooltip">
                  <span v-if="!$v.details.payment_method.required">Payment Method is required</span>
                </div>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="form-group">
                <label for="paid_amount">
                  Amount Paid ({{ this.$currency }})
                  <span class="text-danger">*</span>
                </label>

                <input
                  id="paid_amount"
                  type="text"
                  class="form-control pl-2"
                  name="paid_amount"
                  v-model.trim="$v.details.paid_amount.$model"
                  :class="{
                    'is-invalid': $v.details.paid_amount.$error,
                    'is-valid': !$v.details.paid_amount.$invalid,
                  }"
                />
                <div class="invalid-tooltip">
                  <span v-if="!$v.details.paid_amount.required">Paid Amount is required</span>
                </div>
              </div>
            </div>
          </div>

          <div class="col-12 p-0">
            <div class="form-group">
              <label for="note">Note (if needed)</label>
              <textarea
                v-model="details.note"
                class="form-control"
                id="note"
                name="note"
                autocomplete="off"
              ></textarea>
            </div>
          </div>

          <!-- <div class="form-group">
                <label for="note">Note (If Needed)</label>
                <textarea
                  name="note"
                  id="note"
                  v-model="details.note"
                  class="form-control pl-2"
                ></textarea>
          </div>-->

          <div class="mt-3">
            <button
              type="button"
              class="custom-btn2"
              @click="updatePayment(0)"
              :disabled="isLoadingArray[0]"
            >
              <div v-if="isLoadingArray[0]" class="lds-dual-ring"></div>
              <span v-else>Update sale</span>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import { mapState, mapActions, mapGetters } from "vuex";
import { required, numeric } from "vuelidate/lib/validators";
export default {
  name: "editPaymentReceivableForm",
  props: {
    enableProductStatus: Boolean,
    oldData: Object,
  },
  watch: {
    oldData: function() {
      // watch it
      this.details.date = this.oldData.sale.date;
      this.details.reference = this.oldData.sale.reference;
      this.details.tax_percentage = this.oldData.sale.tax_percentage;
      this.details.shipping_amount = this.oldData.sale.shipping_amount;
      this.details.paid_amount = this.oldData.sale.paid_amount;
      this.details.status = this.oldData.sale.status;
      this.details.payment_status = this.oldData.sale.payment_status;
      this.details.payment_method = this.oldData.sale.payment_method;
      this.details.note = this.oldData.sale.note;
      this.details.total_amount = this.oldData.sale.total_amount;
      this.details.discount_amount = this.oldData.sale.discount_amount;
      this.details.tax_amount = this.oldData.sale.tax_amount;
      this.details.discount_percentage = this.oldData.sale.discount_percentage;
      this.details.due_amount = this.oldData.sale.due_amount;
      this.details.id = this.oldData.sale.id;

      this.updateShippingValue(this.details.shipping_amount); 
      this.updateDiscountPercentageValue(this.oldData.sale.discount_percentage);
      this.updateOrderPercentageValue(this.oldData.sale.tax_percentage);

      const products=this.oldData.saleDetail;
      if(products.length>0)
      {
        products.forEach(element => {
          const product={name:element.product.product_name, products:element.product, age:50, value:element.quantity};
          this.addProductToCart({
          product: product,
          quantity: element.quantity,
          });
        });
      }
     
      if (this.oldData.sale.manufacturer) {
        this.showPeople = 2;
        this.isCheckedmanufacture = true;
      } else if (this.oldData.sale.supplier) {
        this.showPeople = 1;
        this.isChecked = true;
      }
    }
  },
  data() {
    return {
      manufacture: "",
      showPeople: 1,
      customersOptions: [],
      details: {
        date: "",
        reference: "",
        tax_percentage: 0,
        discount_percentage: 0,
        shipping_amount: 0,
        paid_amount: 0,
        status: "",
        payment_status: "",
        payment_method: "",
        note: "",
        total_amount: 0,
        discount_amount: 0,
        tax_amount: 0,
        id: "",
        due_amount: ""
      },
      customer_id: "",
      API_URL: this.$productStorageURL,
      isLoadingArray: [],
      isChecked: false,
      isCheckedmanufacture: false
    };
  },

  validations: {
    details: {
      date: {
        required
      },
      reference: {
        required
      },
      status: {
        required
      },
      paid_amount: {
        required,
        numeric
      },

      payment_method: {
        required
      },
       customer_id: {
        required
      }
    },
  },
  methods: {
    ...mapActions({
      addProductToCart: "cart/addProductToCart",
      removeProductFromCart: "cart/removeProductFromCart",
      increaseQuantityFromCart: "cart/increaseQuantityFromCart",
      decreaseQuantityFromCart: "cart/decreaseQuantityFromCart",
      updateOrderTaxPer: "cart/updateOrderTaxPer",
      updateDiscountPer: "cart/updateDiscountPer",
      updateShipping: "cart/updateShipping",
       clearCartItems: "cart/clearCartItems",
    }),
    productIncrement(item) {
      this.increaseQuantityFromCart({
        product: item
      });
    },
    productdecrement(item) {
      this.decreaseQuantityFromCart({
        product: item
      });
    },
    deleleItem(item) {
      this.removeProductFromCart({
        product: item
      });
    },
    updateShippingValue(value) {
      value = value ? value : 0;
      this.updateShipping({
        value: value
      });
    },
    updateDiscountPercentageValue(value) {
      value = value ? value : 0;
      this.updateDiscountPer({
        value: value
      });
    },
    updateOrderPercentageValue(value) {
      value = value ? value : 0;
      this.updateOrderTaxPer({
        value: value
      });
    },
    callModal: function() {
      this.$root.$emit("openAddPaymentReceiveModal");
    },
    checkOptionsPeople(id) {
      this.showPeople = id;
    },

    getCustomers() {
      axios
        .get(`${process.env.VUE_APP_API_URL}customer/getCustomer`)
        .then(response => {
          this.customersOptions = [];
          if (response.data.statusCode == 200) {
            this.customersOptions = response.data.data;
          }
        });
    },

    updatePayment: function(index) 
    {
      this.details.total_amount = this.cartTotalPrice;
      this.details.discount_amount = this.discountAmount;
      this.details.tax_amount = this.orderTaxAmount;
      if(this.enableProductStatus == true)
      {
           this.details.cart = this.cart;
      }
      else
      {
         this.details.cart = [];
      }
      this.$v.details.$touch();

        if (this.$v.details.$pendding || this.$v.details.$error 
      ) return
      this.$set(this.isLoadingArray, index, false);

      axios
        .post(
          process.env.VUE_APP_API_URL + "paymentReceivable/update",
          this.details
        )
        .then(response => {
          this.$set(this.isLoadingArray, index, false);
          if (response.data.statusCode === 200) {
            this.$router.push({ name: "PaymentReceivable" });
          } else {
            alert(response.data.message);
          }
        })
        .catch(error => {
          this.$set(this.isLoadingArray, index, false);
          if (error.response.status === 500) {
            alert(error.response.data.message);
          }
        });
    }
  },

  computed: {
    ...mapState("cart", ["cart"]),
    ...mapGetters("cart", ["cartTotalPrice"]),
    ...mapState("cart", ["orderTaxPercentage"]),
    ...mapState("cart", ["discountPercentage"]),
    ...mapState("cart", ["shipping"]),
    ...mapGetters("cart", ["orderTaxAmount"]),
    ...mapGetters("cart", ["discountAmount"])
  },
  mounted() {
    this.clearCartItems();
    this.getCustomers();
  }
};
</script>

<style></style>
