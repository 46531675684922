<template>
  <div>
    
    <table class="table table-responsive-md table-sm">
      <thead class="dark">
        <tr>
          <th scope="col" class="text-center">Reference</th>
          <th scope="col" class="text-center">Customer</th>
          <th scope="col" class="text-center">Total Amount ({{this.$currency}})</th>
          <th scope="col" class="text-center">Paid Amount ({{this.$currency}})</th>
          <th scope="col" class="text-center">Due Amount ({{this.$currency}})</th>
          <th scope="col" class="text-center">Payment Status</th>
          <th scope="col" class="text-center">Status</th>
          <th scope="col" class="text-center">Actions</th>
        </tr>
      </thead>

      <tbody v-if="showLoader == 1">
        <tr class="shimmer-td">
          <td colspan="9">
            <content-placeholders class="shimmer" :rounded="true">
              <content-placeholders-text :lines="10" />
            </content-placeholders>
          </td>
        </tr>
      </tbody>

      <tbody v-if="showLoader == 0">
        <tr v-for="(payment, index) in displayedPaymentReceivables" :key="index">
          <td>
            <div class="text-center company-link">
              <span>{{ payment.reference }}</span>
            </div>
          </td>
          <td>
            <div class="text-center company-link">
              <span v-if="payment.customer_id && payment.customer"
                ><b>Customer -</b>
                {{ payment.customer.customer_name }}</span
              >
            </div>
          </td>
          <td>
            <div class="text-center company-size">
              <span>{{ payment.total_amount }}</span>
            </div>
          </td>
          <td>
            <div class="text-center company-location">
              <span>{{ payment.paid_amount }}</span>
            </div>
          </td>
          <td>
            <div class="text-center company-location">
              <span>{{ payment.due_amount }}</span>
            </div>
          </td>
          <td>
            <div class="text-center company-location">
              <button class="active" v-if="payment.payment_status == 'Paid'">
                {{ payment.payment_status }}
              </button>
              <button class="terminated" v-else>
                {{ payment.payment_status }}
              </button>
            </div>
          </td>
          <td class="text-center">
            <button class="active" v-if="payment.status == 'Completed'">
              {{ payment.status }}
            </button>
            <button class="terminated" v-else>{{ payment.status }}</button>
          </td>
          <td class="position-relative">
            <Eclips :payment="payment" />
          </td>
        </tr>
      </tbody>
      <tbody v-if="showLoader == 0 && displayedPaymentReceivables.length == 0">
        <tr class="no-data">
          <td colspan="9" class="shimmer">
            No Data Found.
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</template>

<script>

import Eclips from "./eclips.vue";
export default {
  name: "PaymentReceivableTable",
  props: {
    displayedPaymentReceivables: Array,
    showLoader: Number,
  },
  
  components: { Eclips },

  methods: {
  },
  computed: {},
  mounted() {},
};
</script>

<style></style>
