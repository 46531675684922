<template>
  <div class="registry-wrapper-bottom">
    <h6>{{ count }} {{ module }}</h6>
    <nav aria-label="Page navigation example">
      <ul v-if="page" class="pagination">
        <li
          :class="{ disabled: page.current_page === 1 }"
          class="page-item previous-item"
        >
          <router-link
            :to="{ query: { page: page.current_page - 1 } }"
            class="page-link"
            ><img src="../../assets/images/left.png" alt=""
          /></router-link>
        </li>
        <li
          v-for="page in page.last_page"
          :key="page"
          :class="{ active: page.current_page === page }"
          class="page-item"
        >
          <router-link :to="{ query: { page: page } }" class="page-link">{{
            page
          }}</router-link>
        </li>
        <li
          :class="{ disabled: page.current_page === page.last_page }"
          class="page-item next-item"
        >
          <router-link
            :to="{ query: { page: page.current_page + 1 } }"
            class="page-link"
            ><img src="../../assets/images/right.png" alt=""
          /></router-link>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
export default {
  name: "DatatableBottom",
  props: {
    page: Object,
    count: Number,
    module: String,
  },
};
</script>

<style></style>
